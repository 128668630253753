import React, { useState } from 'react';
import jokes from './jokes.json'; // 농담 데이터가 있는 JSON 파일
import './App.css';
import characterImage from './images/joke2.png';

function App() {
  const [joke, setJoke] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  const getRandomJoke = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 500); // 여기서 500은 밀리세컨드 단위로, 원하는 시간으로 조정 가능합니다.
    const randomIndex = Math.floor(Math.random() * jokes.length);
    setJoke(jokes[randomIndex].joke);
  };

  return (
    <div className="App center-container">
      <h1>Random Joke Generator</h1>
      <img
        className={`character ${isClicked ? 'enlarged' : ''}`}
        src={characterImage}
        alt="Character"
        onClick={getRandomJoke}
      />
      <div className="joke-box">
        <p>{joke}</p>
      </div>
    </div>
  );
}

export default App;
